import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "AWS Scaling - The ELB Surge Queue",
  "date": "2021-09-15T13:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/aws-elb-surge-queue/",
  "category": "Software",
  "tags": ["til", "aws", "elb", "scaling", "cloud"],
  "description": "During large surges in traffic Elastic Load Balancers take time to spin up new compute in order to handle the sudden increase in load."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`During large, sudden bursts of traffic, AWS Elastic Load Balancers (ELB) take time to spin up new compute in order to handle the sudden increase in load.`}</p>
    <p>{`During this time, if the ELB is unable to service all incoming requests, they will be pushed on to the ELB's `}<em parentName="p">{`surge queue`}</em>{`.`}</p>
    <p>{`This surge queue has a maximum length of 1024 requests, and this length cannot be configured.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0faa91b3d28e1b32ad6a62d04ca01cdc/9c177/surge_queue_filling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.079754601226995%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVQoz5VRXY+CMBDs//93agoYo0R4w1Oo0E9o57KrwuE9eLfJprvD7sy0iP1+j81mgzzPIaVElmV8brdbNE0DpRTatuXsum6V7xjNi9PxiExKFEWBw+GAqqpwaS5ck8huJ1GWFc7nioXIQJYVkDLH6VRyT3VZnlHXNcTl64pb28EYA601rLVwzs39MGiujbHcL2meM2bGrjcFYZ3DNEX8N8IYEVNaYT6MEN57pJT+nEySEowLCGHiOsbHNyBBGGsRY/xMFJeaSCwRjg/CZSZChBA+kpEyEUxPYYqZEOtZQT/g5ZBcxLfrva6orcc4PRxR0JVdGGdns0Pv3a8HX5wt4fzIhLREwkTIAutFCALvxkNpj7v26E1A21u0vWOMcePRDY6T8KuyUNqh/7FH52ADvgFeUgeOoTRCLQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Surge queue filling up to the maximum 1024 requests",
            "title": "Surge queue filling up to the maximum 1024 requests",
            "src": "/static/0faa91b3d28e1b32ad6a62d04ca01cdc/a6d36/surge_queue_filling.png",
            "srcSet": ["/static/0faa91b3d28e1b32ad6a62d04ca01cdc/222b7/surge_queue_filling.png 163w", "/static/0faa91b3d28e1b32ad6a62d04ca01cdc/ff46a/surge_queue_filling.png 325w", "/static/0faa91b3d28e1b32ad6a62d04ca01cdc/a6d36/surge_queue_filling.png 650w", "/static/0faa91b3d28e1b32ad6a62d04ca01cdc/9c177/surge_queue_filling.png 880w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If this surge queue fills, additional requests to the ELB will fail in what's known as surge queue "spillover". Spillover is any request that couldn't be queued on the ELB surge queue due to it being full.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/68636e1b9094c3e52ec2670e49300b16/cc155/surge_queue_spillover.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.079754601226995%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA50lEQVQoz62PAWuDMBCF/f8/T0lSaokpglVDpyZOaUx85TI62kHFjQUeuXv38oVL8jxHmqbgnH+LMYYsy9A0DYwx6LoOfd9jGIZNaa2RSCkhBAeBlVKoqgp1Xcee4KeTxOXSQqlzzB2POYQ4gPMDpCxiz5hAUZxRliWSVms0rYa1FuM4YpomzPMca2MsrCWP+k9Ya2CNjT6JMvTukbt+9EgI4BaPPWcFENb38/nmvoAhBKzrGu/n+qe3eI+bW178x4xEXya0nn8yt+R9iNu8mxM8AsMvgdjI/A2IfwQuGytHoHNuN3CP7i0tCd7ROWHZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "When the surge queue fills, there is \"spillover\" and requests will fail. This manifests as 5XX responses from the ELB.",
            "title": "When the surge queue fills, there is \"spillover\" and requests will fail. This manifests as 5XX responses from the ELB.",
            "src": "/static/68636e1b9094c3e52ec2670e49300b16/a6d36/surge_queue_spillover.png",
            "srcSet": ["/static/68636e1b9094c3e52ec2670e49300b16/222b7/surge_queue_spillover.png 163w", "/static/68636e1b9094c3e52ec2670e49300b16/ff46a/surge_queue_spillover.png 325w", "/static/68636e1b9094c3e52ec2670e49300b16/a6d36/surge_queue_spillover.png 650w", "/static/68636e1b9094c3e52ec2670e49300b16/cc155/surge_queue_spillover.png 886w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When the surge queue fills, there is "spillover" and requests will fail. `}</p>
    <p>{`When requests start to spillover, the ELB will begin to return 502 or 504 responses. As such, it's a good idea to have automated monitoring in place on the spillover metrics.`}</p>
    <p>{`This issue will typically mitigate itself after the ELBs have scaled up.`}</p>
    <p>{`We can prevent this by "pre-warming" the ELB, to ensure it is scaled appropriately in advance of the surge. This pre-warming may require contacting AWS support.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      